import React, {useState, useEffect, Fragment} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

const Loans = () => {
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    const dispatch = useDispatch();
    const initialLogin = {
        amount: 0,
        description: '',
        loan_type: ''
    }
    
    
    const [error, setErros] = useState('');
    const [loanForm, setloanForm] = useState(initialLogin);
    const cards = useSelector((state) => state.history.cards);
    const loans = useSelector((state) => state.history.loans);

    const onChange = (e) =>
    setloanForm({ ...loanForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postloan } = useMutation(

        async () => {
            return await apiClient.post(`/api/loan-application`, {
                amount: loanForm.amount,
                description: loanForm.description,
                loan_type: loanForm.loan_type
              });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );

      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postloan();
      } 

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                    APPLY FOR LOAN
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    Note: kindly note that only users who have participated in the Last plan are eligible to apply.
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2, marginTop: 5 }}>
                    <InputLabel htmlFor="loan_type">Loan Type</InputLabel>
                    {(error !== '' && error.loan_type) ?
                        <Select
                            error
                            onChange={onChange}
                            labelId="loan_type"
                            id="loan_type"
                            name="loan_type"
                            label={`Loan Type`}
                            helperText={error.loan_type}
                            >
                            <MenuItem selected value=''>
                            loan Type
                            </MenuItem> 
                             <MenuItem selected value="Home Loan">
                             Home Loan
                            </MenuItem>
            
                            <MenuItem selected value="Car Loan">
                            Car Loan
                            </MenuItem>

                            <MenuItem selected value="Others">
                            Others
                            </MenuItem>             
                            
                        </Select>
                    :
                        <Select
                            onChange={onChange}
                            labelId="loan_type"
                            name="loan_type"
                            id="loan_type"
                            label={`loan Type`}
                            >
                            <MenuItem selected value=''>
                            loan Type
                            </MenuItem> 
                             <MenuItem selected value="Home Loan">
                             Home Loan
                            </MenuItem>
            
                            <MenuItem selected value="Car Loan">
                            Car Loan
                            </MenuItem>

                            <MenuItem selected value="Others">
                            Others
                            </MenuItem> 
                        </Select>
                    }
                    <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    {error !== '' && error.loan_type}
                    </Typography>

                </FormControl>


                <FormControl fullWidth={true} sx={{ mx: 0, minWidth: 50, marginBottom: 2 }}>
                    <InputLabel htmlFor="amount">Amount</InputLabel>
                    {(error !== '' && error.amount) ?

                    <OutlinedInput
                    onChange={onChange}
                    error
                    id="amount"
                    type={'number'}
                    name="amount"
                    label="Amount"
                    helperText={error.amount}
                    />
                    :
                    <OutlinedInput
                    onChange={onChange}
                    id="amount"
                    type={'number'}
                    name="amount"
                    label="Amount"
                    />
                    }
                    <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    {error !== '' && error.amount}
                    </Typography>
                </FormControl>
                
                <FormControl fullWidth={true} sx={{ mx: 0, minWidth: 50, marginBottom: 2 }}>
                    <InputLabel htmlFor="description">Additional Details</InputLabel>
                    {(error !== '' && error.description) ?

                    <OutlinedInput
                    onChange={onChange}
                    error
                    id="description"
                    type={'text'}
                    name="description"
                    label="Additional Details"
                    helperText={error.description}
                    />
                    :
                    <OutlinedInput
                    onChange={onChange}
                    id="description"
                    type={'text'}
                    name="description"
                    label="Additional Details"
                    />
                    }
                    <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    {error !== '' && error.description}
                    </Typography>
                </FormControl>

                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Sending...' : 'Send Request'}</Button>
                    </CardActions>
                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
            {loans.length > 0 &&
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

        {loans.map(loan => (
        <Fragment key={loan.id}>
        <ListItem sx={{cursor: 'pointer'}} alignItems="flex-start">
        <ListItemAvatar>
            
            {loan.status === 'pending' &&
            <Avatar  sx={{ bgcolor: '#f7a21d' }}>
            <PendingIcon/>
            </Avatar>
            }
            
            {loan.status === 'approved' &&
            <Avatar  sx={{ bgcolor: '#37dc8a' }}>
            <CheckCircleIcon/>
            </Avatar>
            }

            {loan.status === 'declined' &&
            <Avatar  sx={{ bgcolor: '#dd0a22' }}>
            <DangerousIcon/>
            </Avatar>
            
            }

            {loan.status === 'completed' &&
            <Avatar  sx={{ bgcolor: '#ccc6c6' }}>
            <DoneAllIcon/>
            </Avatar>
            
            }
            
            
        </ListItemAvatar>

        <ListItemText
            primary={`${formatPrice(loan.amount)}`}
            secondary={
            <React.Fragment>
                <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
                >
                {loan.description}
                </Typography>
            </React.Fragment>
            }
        />
        

            <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-even', alignContent: 'center', alignItems: 'right'}}>
                <Typography color={loan.status === 'pending'? 'secondary': 'primary.success'}  edge="end">
                {loan.status}
                </Typography>
            </Box>

        
        </ListItem>
        <Divider  component="li" variant="inset" />
        </Fragment>
        ))}
        </List>
        }
            </Grid>
        </Grid>
    </div>
  )
}

export default Loans
