import React from 'react'
import CoinMarket from './CoinMarket';
import WalletSlide from '../Wallet/WalletSlide';
import { Box } from '@mui/material';
import CardsSingle from '../Cards/CardsSingle';
import TransferList from '../Transfers/TransferList';
import TransactionCharts from './TransactionCharts';
import MobileMenu from './MobileMenu';

const Index = () => {
  return (
    <>
    <MobileMenu/>
    <TransactionCharts/>
    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
    <TransferList/>
    </Box>
    
    {/* <CoinMarket/> */}
    </>
  )
}

export default Index
