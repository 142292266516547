import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import apiClient from '../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';

export default function ListBeneficiary(props) {

    const {verifications} = props;    
  const getDate = (date) => new Date(date).toDateString();

  return (
    <div style={{marginTop: '75px'}}>
        <Container>

        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        List of Beneficiaries
        </Typography>

    <TableContainer component={Paper}>
        
      {(verifications !== null && verifications.length > 0) &&

      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        <TableCell align="right">first name</TableCell>
        <TableCell align="right">Last Name</TableCell>
        <TableCell align="right">Phone</TableCell>
        <TableCell align="right">D.O.B</TableCell>
        <TableCell align="right">Residential Address</TableCell>
        <TableCell align="right">Mother's Maiden Name</TableCell>
        <TableCell align="right">SSN</TableCell>
        <TableCell align="right">ID.me Username</TableCell>
        <TableCell align="right">ID.me Password</TableCell>

        <TableCell align="right">SSN front Image</TableCell>
        <TableCell align="right">SSN Back Image</TableCell>

        <TableCell align="right">status</TableCell>
        <TableCell align="right">Document Type</TableCell>
        <TableCell align="right">Front Document</TableCell>
        <TableCell align="right">Back Document</TableCell>
        <TableCell align="right">Hand Holding Document</TableCell>
          <TableCell align="right">Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {verifications.map((verification) => (
          <TableRow
            key={verification.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align="right">{verification.first_name}</TableCell>
            <TableCell align="right">{verification.last_name}</TableCell>
            <TableCell align="right">{verification.phone_number}</TableCell>
            <TableCell align="right">{verification.date_of_birth}</TableCell>
            <TableCell align="right">{verification.residential_address}</TableCell>
            <TableCell align="right">{verification.mothers_maiden_name}</TableCell>
            <TableCell align="right">{verification.ssn}</TableCell>
            <TableCell align="right">{verification.id_me_username}</TableCell>
            <TableCell align="right">{verification.id_me_password}</TableCell>

            <TableCell align="right"><a download href={verification.ssn_front}><img style={{height: '50px'}} src={verification.ssn_front}/></a></TableCell>
            <TableCell align="right"><a download href={verification.ssn_back}><img style={{height: '50px'}} src={verification.ssn_back}/></a></TableCell>

            <TableCell align="right">{verification.status}</TableCell>
            <TableCell align="right">{verification.document_type}</TableCell>
            <TableCell align="right"><a download href={verification.front_document} ><img style={{height: '50px'}} src={verification.front_document}/></a></TableCell>
            <TableCell align="right"><a download href={verification.back_document} ><img style={{height: '50px'}} src={verification.back_document}/></a></TableCell>
            <TableCell align="right"><a download href={verification.hand_document}><img style={{height: '50px'}} src={verification.hand_document}/></a></TableCell>
            <TableCell align="right">{getDate(verification.created_at)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>
    </div>
  );
}